<template>
  <label ref="labelRef" class="input">
    <div v-if="placeholder" class="input__placeholder text-h5">
      {{ placeholder }}
    </div>
    <div class="input-field" :class="inputClass">
      <span v-if="icon" class="input-field-icon">
        <BaseIcon :icon="icon" />
      </span>
      <input
        ref="inputRef"
        v-model="value"
        :type="type"
        class="text-body"
        :name="label"
        :disabled="disabled"
        :placeholder="innerPlaceholder"
        maxlength="100"
        @blur="handleInputBlur"
        @focus="handleInputFocus"
      />
      <span
        v-if="clearable && isVisibleClearableButton"
        ref="clearIconRef"
        tabindex="0"
        class="input-field-icon-right"
        @click.prevent="clearInputValue"
      >
        <BaseIcon class="input-field-clear-icon" icon="cross" />
      </span>
    </div>
    <p v-if="helpText" class="input__help-text">{{ helpText }}</p>
    <div v-if="!noError" class="input-error">
      <transition name="input_error">
        <span v-if="validatorErrorMessage || error" class="input-error__text">
          {{ validatorErrorMessage ? $t(validatorErrorMessage) : $t(error) }}
        </span>
      </transition>
    </div>
  </label>
</template>

<script lang="ts">
import type { PropType } from "vue";

import { ref, computed, nextTick } from "vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";

import removeWhitespaces from "~/utils/removeWhitespaces";

export default defineNuxtComponent({
  components: { BaseIcon },
  props: {
    modelValue: {
      type: [Number, String, null] as PropType<number | string | null>,
      default: "",
    },
    error: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    noError: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    upperCase: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    label: {
      type: String as PropType<string>,
      default: null,
    },
    icon: {
      type: String as PropType<string | null>,
      default: null,
    },
    placeholder: {
      type: String as PropType<string | null>,
      default: null,
      required: false,
    },
    innerPlaceholder: {
      type: String as PropType<string | undefined>,
      required: false,
    },
    validator: {
      type: Function,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rounded: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    clearable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    type: {
      type: String as PropType<string>,
      default: "text",
    },
    helpText: {
      type: String as PropType<string>,
      default: null,
    },
    trim: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ["change", "update:modelValue", "isError", "focus", "blur"],

  setup(props, { emit }) {
    const inputRef = ref<HTMLInputElement | undefined>();
    const labelRef = ref<HTMLElement | undefined>();
    const clearIconRef = ref<HTMLElement | undefined>();

    const isVisibleClearableButton = ref(false);

    const value = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        if (props.trim && val) {
          val = removeWhitespaces(val);
        }

        emit("update:modelValue", val);
      },
    });

    const validatorErrorMessage = ref(null);

    watch(
      () => props.modelValue,
      () => {
        validatorErrorMessage.value = null;

        if (props.clearable && props.modelValue) {
          isVisibleClearableButton.value = true;
        } else {
          isVisibleClearableButton.value = false;
        }
      }
    );

    const isFocus = ref(false);

    function clearInputValue() {
      emit("update:modelValue", "");
    }
    function handleInputFocus() {
      emit("focus");
      isFocus.value = true;
    }
    async function handleInputBlur() {
      await nextTick();

      isFocus.value = false;
      emit("blur");
      if (props.validator) {
        validatorErrorMessage.value = props.validator(props.modelValue);
        if (props.label) {
          const isError = !!validatorErrorMessage.value;
          emit("isError", { label: props.label, error: isError });
        }
      }
    }

    const inputClass = computed(() => ({
      "upper-case": props.upperCase,
      "input-error-text": validatorErrorMessage.value,
      input_rounded: props.rounded,
      input_disabled: props.disabled,
      input_focus: isFocus.value,
    }));

    return {
      inputRef,
      labelRef,
      clearIconRef,
      isFocus,
      value,
      inputClass,
      validatorErrorMessage,
      clearInputValue,
      handleInputFocus,
      handleInputBlur,
      isVisibleClearableButton,
    };
  },
});
</script>
